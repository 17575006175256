import React from 'react';
import { SITE_TITLE_APPEND } from '../constants';
import Seo from '../components/seo';
import Layout, { PageContents } from '../components/layout';
import Header from '../components/header';

// markup
const AboutPage = () => {
  return (
    <Layout>
      <main>
        <Seo title={`About ${SITE_TITLE_APPEND}`} />
        <Header />

        <PageContents title="Dravida Entertainments">
          <div className="mb-4 custom-text">
            <p>
              Incepted in 2013, Dravida Entertainments have changed hands and
              partners over its course of growth. Dravida started off with its
              base in Bangalore and then later shifted to Ernakulam.
            </p>
            <p>
              With a work base of over 10 short films, 12 musicals and 1 web
              series, Dravida has been a trusted name in the online
              entertainment industry.
            </p>
            <p>
              A decade of efforts to create a parallel production etiquette,
              evolving with changes, inside and outside, growing stronger at
              every turn. We are a new team with a new direction. New ideas, new
              possibilities. We are here to sustain and make a mark.
            </p>
            <p>
              Dravida Entertainments is spearheaded by Jubith Namradath. Other
              major share holders are Jijith Nambradth, Divya Usha Gopinath, M
              Santhana Gopalakrishnan, Rajesh Vazhappillil, Swaroop Mattam and Anand
              Radhakrishnan.
            </p>

            <p>
              All works are available in{' '}
              <a
                href="https://www.youtube.com/@enterdravida"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  borderBottom: '2px dotted #ccc',
                  textDecoration: 'none',
                }}
              >
                Dravida Entertainments YouTube channel.
              </a>
            </p>
          </div>
        </PageContents>
      </main>
    </Layout>
  );
};

export default AboutPage;
